html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  min-width: $base-min-width;
  font-family: 'Montserrat', sans-serif;

  &.no-scroll {
    white-space: nowrap;
    overflow: hidden;
    position: fixed;
    width: 100%;
    height: 100%;
  }
}

img {
  width: 100%;
}

ul {
  @extend %listreset;
}

a {
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}

/* Scrollbar */
::-webkit-scrollbar {
  width: 8px;
  height: 7px;
}
::-webkit-scrollbar-track {
  border-radius: 5px;
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.modal.show:not(:last-of-type) {
  .modal-dialog {
    display: none !important;
  }
}

ngb-rating {
  .star {
    position: relative;
    display: inline-block;
    font-size: 1.2rem;
    color: #d3d3d3;
    .full {
      color: #f6a609;
    }
    .half {
      position: absolute;
      display: inline-block;
      overflow: hidden;
      color: #f6a609;
    }
  }
}

.visually-hidden {
  // use it to hide elements in ngbootstrap like in rating and pagination(current word)....
  display: none;
}

.ltr {
  direction: ltr;
}

.rtl {
  direction: rtl;
}

// bootstrap carousel
.carousel {
  height: 100%;
  border: 1px solid #2222;
  border-radius: 7px;

  .visually-hidden {
    display: none;
  }
  .carousel-inner {
    height: 100%;
  }

  .carousel-item {
    height: 100%;
  }

  .carousel-indicators {
    bottom: -2.5rem;
    button {
      background-color: #2222;
      padding: 3px 15px;
      margin: 5px;
      &.active {
        background-color: $primary;
      }
    }
  }
}

// lg
@media (max-width: 991px) {
  :root {
    font-size: 14px;
  }
}
