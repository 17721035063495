// custom variables and override bootstrap variables
$base-min-width: 320px;

//== Colors
//
$white: #fff;
$background: #f9f9fa;
$light: #f9f9fa;

// primary palette
$primary: #1b448d;
$primary-states: #5477bb;

$secondary: #cd3228;
$secondary-states: #ee5851;

// black palette
$black-100: #313131;
$black-60: #52575c;
$black-50: #a0a4a8;
$black-40: #cacccf;
$black-30: #dbdde0;
$black-20: #e8e8e8;
$black-10: #e0e0e0;
$border-color: $black-10;

// warning palette
$warning-light: #ffbc1f;
$warning-default: #f6a609;
$warning-dark: #e89806;

// success palette
$success-light: #40dd7f;
$success-default: #2ac769;
$success-dark: #1ab759;

// danger palette
$danger-light: #ff6262;
$danger-default: #fb4e4e;
$danger-dark: #e93c3c;

//== Scaffolding
//
$link-hover-decoration: underline;
$link-hover-color: $primary;

//== Typography
//

$montserrat: "Montserrat", sans-serif;
$montserrat-bold: "Montserrat-Bold",  sans-serif ,"Helvetica Neue", ;
$montserrat-semibold: "Montserrat-Sb",  sans-serif ,"Helvetica Neue",;
$montserrat-medium: "Montserrat-Md",  sans-serif ,"Helvetica Neue",;
$montserrat-light: "Montserrat-Lt",  sans-serif ,"Helvetica Neue",;

$cairo-light: "Cairo-Lt", sans-serif;
$cairo: "Cairo", sans-serif;
$cairo-semibold: "Cairo-Sb", sans-serif;
$cairo-bold: "Cairo-Bd", sans-serif;

$letter-spacing-base: 0px;

$font-size-h1: 3.5rem;
$font-weight-h1: 700;
$line-height-h1: 1.3;
$letter-space-h1: $letter-spacing-base;

$font-size-h2: 2.7rem;
$font-weight-h2: 700;
$line-height-h2: 1.2;
$letter-space-h2: $letter-spacing-base;

$font-size-h3: 2rem;
$font-weight-h3: 700;
$line-height-h3: 1.2;
$letter-space-h3: $letter-spacing-base;

$font-size-h4: 1.75rem;
$font-weight-h4: 600;
$line-height-h4: 1.2;
$letter-space-h4: $letter-spacing-base;

$font-size-h5: 1.5rem;
$font-weight-h5: 600;
$line-height-h5: 1.2;
$letter-space-h5: $letter-spacing-base;

$font-size-h6: 1.25rem;
$font-weight-h6: 500;
$line-height-h6: 1.2;
$letter-space-h6: $letter-spacing-base;

$font-size-body: 1rem;
$font-weight-body: 400;
$line-height-body: 1.2;
$letter-space-body: $letter-spacing-base;

$font-size-small: 0.75rem;
$font-weight-small: 500;
$line-height-small: 1.1;
$letter-space-small: $letter-spacing-base;

$font-size-tiny: 0.6rem;
$font-weight-tiny: 500;
$line-height-tiny: 0.95;
$letter-space-tiny: $letter-spacing-base;

$font-size-anchor: 1rem;
$font-weight-anchor: 600;
$line-height-anchor: 1.2;
$letter-space-anchor: $letter-spacing-base;

//== Container sizes
//
$container-lg: 1310px;

//== Buttons
//
$font-size-button: 0.8rem;
$line-height-button: 1.1;
$font-weight-button: 600;
$letter-space-button: $letter-spacing-base;

//== Forms
//
$input-color-placeholder: $black-30;
$input-color: $black-100;
$input-border: $black-50;
$input-border-focus: $primary;
$input-border-error: $danger-default;
$input-height-large: 52px;
$input-height-base: 48px;

$border-radius-base: 3px;