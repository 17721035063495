// NOTE: the .small classes on the headers bump the size / font family down to the
// next smallest header (e.g. h1.small takes font and size of h2)

h1,
.h1 {
  @extend %text;
  font: $font-size-h1 $montserrat-bold;
  font-weight: $font-weight-h1;
  letter-spacing: $letter-space-h1;

  &.small {
    font-size: $font-size-h2;
    line-height: $line-height-h2;
  }
}

h2,
.h2 {
  @extend %text;
  font: $font-size-h2 $montserrat-bold;
  font-weight: $font-weight-h2;
  letter-spacing: $letter-space-h2;

  &.small {
    font-size: $font-size-h3;
    line-height: $line-height-h3;
  }
}

h3,
.h3 {
  @extend %text;
  font: $font-size-h3 $montserrat-bold;
  font-weight: $font-weight-h3;
  letter-spacing: $letter-space-h3;

  &.small {
    font-size: $font-size-h4;
  }
}

h4,
.h4 {
  @extend %text;
  font: $font-size-h4 $montserrat-bold;
  font-weight: $font-weight-h5;
  font-weight: $font-weight-h4;
  letter-spacing: $letter-space-h4;

  &.small {
    font-size: $font-size-h5;
  }
}

h5,
.h5 {
  @extend %text;
  font: $font-size-h5 $montserrat-semibold;
  font-weight: $font-weight-h5;
  letter-spacing: $letter-space-h5;

  &.small {
    font-size: $font-size-h6;
    line-height: $line-height-h6;
  }
}

h6,
.h6 {
  @extend %text;
  font: $font-size-h6 $montserrat-medium;
  font-weight: $font-weight-h6;
  letter-spacing: $letter-space-h6;

  &.small {
    font-size: $font-size-body;
  }
}

p {
  @extend %text;
  font: $font-size-body $montserrat;
  font-weight: $font-weight-body;
  letter-spacing: $letter-space-body;

  &.small {
    font-size: $font-size-small;
    line-height: $line-height-small;
  }

  &.semibold {
    font-weight: 600;
  }
}

.small-text {
  @extend %text;
  font: $font-size-small $montserrat-medium;
  line-height: $line-height-small;
  font-weight: $font-weight-small;
  letter-spacing: $letter-space-small;

  &.small {
    font-size: $font-size-small;
  }

  &.semibold {
    font-weight: 600;
  }

  &.rtl {
    font: $font-size-small $cairo-semibold;
  }
}

.tiny-text {
  @extend %text;
  font: $font-size-tiny $montserrat-medium;
  line-height: $line-height-tiny;
  font-weight: $font-weight-tiny;
  letter-spacing: $letter-space-tiny;

  &.semibold {
    font-weight: 600;
  }
}

button {
  @extend %text;
  font: $font-size-button $montserrat-semibold;
  line-height: $line-height-button;
  font-weight: $font-weight-button;
  letter-spacing: $letter-space-button;

  &.small {
    font-size: $font-size-small;
  }
}

a {
  @extend %text;
  @extend %transition;
  line-height: $line-height-anchor;
  font-weight: $font-weight-anchor;
  letter-spacing: $letter-space-anchor;
}

.rtl {
  direction: rtl;
  text-align: right;

  & *:not(i) {
    font-family: $cairo;
    line-height: 1.2;
  }

  small,
  .font-size-small {
    font-size: 0.8rem;
    line-height: 1.2;
  }

  a {
    font-family: $cairo;
    line-height: 1.2;
  }

  .body-text {
    font-family: $cairo;
  }

  p {
    font-family: $cairo , Arial, sans-serif;;
    line-height: 1.5;
  }

  .small-text {
    font: $font-size-small $cairo;
  }

  .tiny-text {
    font: $font-size-tiny $cairo;
  }

  button {
    font: $font-size-button $cairo;
    font-weight: 700;
  }

  label:not(.form-check-label) {
    font: $font-size-button $cairo;
    letter-spacing: normal !important;
  }

  span {
    font: $font-size-body $cairo;
  }
}

// lg
@media (max-width: 991px) {
  h1 {
    font-size: 2.2rem;
    line-height: 1.2;
  }

  h2 {
    font-size: 1.8rem;
    line-height: 1.2;
  }

  h3 {
    font-size: 1.5rem;
    line-height: 1.2;
  }

  h4 {
    font-size: 1.3rem;
    line-height: 1.2;
  }
}

/* sm */
@media (max-width: 575px) {
  h1 {
    font-size: 1.5rem !important;
    line-height: 1.2;
  }

  h2 {
    font-size: 1.3rem !important;
    line-height: 1.2;
  }

  h3 {
    font-size: 1.2rem !important;
    line-height: 1.2;
  }

  h4 {
    font-size: 1rem;
    line-height: 1.2;
  }
  p {
    line-height: 1.5;
  }
}
