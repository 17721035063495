@font-face {
  font-family: "Montserrat-Lt";
  src: url("/assets/fonts/montserrat-300.woff2") format("woff2"),
       url("/assets/fonts/montserrat-300.woff") format("woff"),
       url("/assets/fonts/montserrat-300.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("/assets/fonts/montserrat-regular.woff2") format("woff2"),
       url("/assets/fonts/montserrat-regular.woff") format("woff"),
       url("/assets/fonts/montserrat-regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat-Md";
  src: url("/assets/fonts/montserrat-500.woff2") format("woff2"),
       url("/assets/fonts/montserrat-500.woff") format("woff"),
       url("/assets/fonts/montserrat-500.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat-Sb";
  src: url("/assets/fonts/montserrat-600.woff2") format("woff2"),
       url("/assets/fonts/montserrat-600.woff") format("woff"),
       url("/assets/fonts/montserrat-600.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat-Bold";
  src: url("/assets/fonts/montserrat-700.woff2") format("woff2"),
       url("/assets/fonts/montserrat-700.woff") format("woff"),
       url("/assets/fonts/montserrat-700.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Cairo-Lt";
  src: url("/assets/fonts/cairo-v6-arabic-300.woff2") format("woff2"),
       url("/assets/fonts/cairo-v6-arabic-300.woff") format("woff"),
       url("/assets/fonts/cairo-v6-arabic-300.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Cairo";
  src: url("/assets/fonts/cairo-v6-arabic-regular.woff2") format("woff2"),
       url("/assets/fonts/cairo-v6-arabic-regular.woff") format("woff"),
       url("/assets/fonts/cairo-v6-arabic-regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Cairo-Sb";
  src: url("/assets/fonts/cairo-v6-arabic-600.woff2") format("woff2"),
       url("/assets/fonts/cairo-v6-arabic-600.woff") format("woff"),
       url("/assets/fonts/cairo-v6-arabic-600.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Cairo-Bd";
  src: url("/assets/fonts/cairo-v6-arabic-700.woff2") format("woff2"),
       url("/assets/fonts/cairo-v6-arabic-700.woff") format("woff"),
       url("/assets/fonts/cairo-v6-arabic-700.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
