@import "~swiper/css";
@import "~swiper/css/navigation";
@import "~swiper/css/pagination";
@import "~swiper/css/autoplay";


.swiper {
  width: 100%;
  height: 100%;
  border-radius: 7px;
}

.swiper-wrapper {
  align-items: center;
}

.swiper-slide {
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;

  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-pagination-bullet {
  transition: 0.5s;
}

.swiper-pagination-bullet-active {
  width: 30px;
  border-radius: 10px;
}

.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: -5px;
}

:root {
  --swiper-theme-color: #1b448d !important;
}
