/* You can add global styles to this file, and also import other style files */

// bootstrap variables
$theme-colors: (
  "primary": #1b448d,
);

#hubspot-messages-iframe-container {
  z-index: 999 !important;
}

/* Importing Bootstrap SCSS file. */
@import "~bootstrap/scss/bootstrap";
@import "./assets/styles/theme";