app-icon {
  fill: currentColor;
  &.transparent {
    fill: transparent;
  }
  &.black {
    fill: $black-100;
  }
  &.primary {
    fill: $primary;
  }
  &.white {
    fill: $white;
  }
  &.orange {
    fill: orange;
  }
  &.reverse {
    transform: scaleX(-1);
  }
  &.rotate90 {
    transform: rotate(90deg);
  }
  &.rotate270 {
    transform: rotate(270deg);
  }
}

a:hover {
  app-icon {
    cursor: pointer;
    &:not(.preserve-color-on-hover) svg use {
      fill: $primary;
    }

    &.highlight-on-hover svg use {
      path {
        stroke: $primary;
      }
    }
  }
}
