@import "mixins/rtl.scss";

body.rtl {
  .ngx-dropdown-button {
    text-align: right !important;
    display: flex !important;
    align-items: center;
  }

  .ngx-dropdown-container {
    .ngx-dropdown-button {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }

    .ngx-dropdown-list-container {
      .search-container {
        input {
          direction: rtl;
          text-align: right;
        }

        label {
          right: 0;
          left: auto !important;
        }
      }
    }
  }

  .dropdown-menu {
    text-align: right;
  }

  input {
    direction: ltr;
    text-align: right;
  }

  .border-right {
    border-right: none !important;
    border-left: 1px solid #2222 !important;
  }

  .border-left {
    border-left: none !important;
    border-right: 1px solid #2222 !important;
  }
}