@use "sass:math";

@mixin marginRtl($break-point: false) {
  @for $i from 0 through 5 {
    $value: 0;

    @if ($i ==1) {
      $value: 0.25;
    }

    @if ($i ==2) {
      $value: 0.5;
    }

    @if ($i ==3) {
      $value: 1;
    }

    @if ($i==4) {
      $value: 1.5;
    }

    @if ($i==5) {
      $value: 3;
    }

    @if ($break-point) {
      .ml-#{$break-point}-#{$i} {
        margin-right: #{$value}rem !important;
        margin-left: 0 !important;
      }

      .mr-#{$break-point}-#{$i} {
        margin-left: #{$value}rem !important;
        margin-right: 0 !important;
      }
    }

    @else {
      .ml-#{$i} {
        margin-right: #{$value}rem !important;
        margin-left: 0 !important;
      }

      .mr-#{$i} {
        margin-left: #{$value}rem !important;
        margin-right: 0 !important;
      }
    }
  }
}

@mixin paddingRtl($break-point: false) {
  @for $i from 0 through 5 {
    $value: 0;

    @if ($i ==1) {
      $value: 0.25;
    }

    @if ($i ==2) {
      $value: 0.5;
    }

    @if ($i ==3) {
      $value: 1;
    }

    @if ($i==4) {
      $value: 1.5;
    }

    @if ($i==5) {
      $value: 3;
    }

    @if ($break-point) {
      .pl-#{$break-point}-#{$i} {
        padding-right: #{$value}rem !important;
        padding-left: 0 !important;
      }

      .pr-#{$break-point}-#{$i} {
        padding-left: #{$value}rem !important;
        padding-right: 0 !important;
      }
    }

    @else {
      .pl-#{$i} {
        padding-right: #{$value}rem !important;
        padding-left: 0 !important;
      }

      .pr-#{$i} {
        padding-left: #{$value}rem !important;
        padding-right: 0 !important;
      }
    }
  }
}

.rtl {
  @include marginRtl;
  @include paddingRtl;

  @media (min-width: 576px) {
    @include marginRtl("sm");
    @include paddingRtl("sm");
  }

  @media (min-width: 768px) {
    @include marginRtl("md");
    @include paddingRtl("md");
  }

  @media (min-width: 992px) {
    @include marginRtl("lg");
    @include paddingRtl("lg");
  }

  @media (min-width: 1200px) {
    @include marginRtl("xl");
    @include paddingRtl("xl");
  }

  .ml-auto {
    margin-left: 0 !important;
    margin-right: auto !important;
  }

  .mr-auto {
    margin-right: 0 !important;
    margin-left: auto !important;
  }
}