button,
.button {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: $border-radius-base;
  padding: 10px 24px;
  border: none;
  background-color: transparent;
  &.small {
    padding: 5px 24px;
  }
  @extend %transition;

  &:hover {
    cursor: pointer;
  }

  &.primary {
    color: $white;
    background: $primary;
    border: 1px solid $primary;

    &:hover {
      color: $white;
      background: $primary-states;
      border: 1px solid $primary-states;
    }

    &:focus {
      background: $white;
      color: $primary-states;
      border: 1px solid $primary-states;
    }

    &:active {
      background: $white;
      color: $primary-states;
      border: 1px solid $primary-states;
    }
  }
  &.secondary {
    color: $primary;
    background: #fff;
    border: 1px solid $primary;

    &:hover {
      color: $white;
      background: $primary;
    }
  }

  &.danger {
    @extend %transition;
    color: $white;
    background: $danger-dark;
    border: 1px solid $danger-dark;

    &:hover {
      color: $white;
      background: $danger-default;
      border: 1px solid $danger-default;
    }

    &:focus {
      color: $white;
      background: $danger-default;
      border: 1px solid $danger-default;
    }

    &:active {
      color: $white;
      background: $danger-light;
      border: 1px solid $danger-light;
    }
  }

  &.dark {
    color: $white;
    background: $black-100;
  }
  &.dark-trans {
    color: $black-100;
    background: none;
    border: 1px solid $black-100;

    &:hover {
      color: $white;
      background: $black-100;
    }
  }

  &.dark-trans-white {
    color: $white;
    background: none;
    border: 1px solid $white;

    &:hover {
      color: $white;
      background: $black-100;
    }
  }

  &.text {
    padding: 0;
    background: none;
    border: none;
    color: $primary;
    &:hover {
      text-decoration: underline;
    }
  }
}
