// IMPORTANT
// DO NOT PUT ANY ACTUAL CSS DECLARATIONS IN THIS FILE. ONLY PRE-PROCESSOR DIRECTIVES SHOULD GO HERE
// OR THEY WILL BE REPEATED IN THE COMBINED VERSION OF ALL COMPONENT CSS FILES

// list reset
%listreset {
  margin: 0;
  padding: 0;
  list-style: none;
}

// clearfix
%clearfix {
  &:after {
    content: '';
    display: block;
    clear: both;
  }
}

// this should be in bootstrap
// .clearfix {
//   @extend %clearfix;
// }

%text {
  margin: 0;
  color: $black-100;

  &.white {
      color: $white;
  }
  &.gray-1 {
      color: $black-60;
  }
  &.gray-2 {
      color: $black-50;
  }
  &.gray-3 {
      color: $black-40;
  }
  &.gray-4 {
      color: $black-30;
  }
  &.optional {
      color: $black-40;
  }
  &.primary {
      color: $primary-states;
  }
  &.warning {
      color: $warning-default;
  } 
  &.success {
      color: $success-default;
  } 
  &.danger {
      color: $danger-default;
  } 
}

// transition
%transition {
  transition: all 0.3s ease-in-out;
}

 
.full-min-height{
  min-height: 100vh
}

.text-bold {
  font-weight: bold;
}

.cursor-pointer {
  cursor: pointer;
}
.scaled{
  transition: all 0.3s ease; 
  cursor: pointer;
  &:hover {
    transform: scale(1.1);
  }
}

.shacked {
  box-shadow: none !important;
  transition: transform 0.3s, box-shadow 0.3s;
  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }
}

.shacked-right {
  box-shadow: none !important;
  transition: transform 0.3s, box-shadow 0.3s;
  &:hover {
    transform: translateX(10px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }
}

.shacked-down {
  box-shadow: none !important;
  transition: transform 0.3s, box-shadow 0.3s;
  &:hover {
    transform: translateY(10px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }
}