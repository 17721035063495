@import "typography";

%bordered {
	border: 1px solid $border-color;
	border-radius: $border-radius-base;

	&:hover {
		border-color: $black-50;
	}

	&:focus {
		border-color: $primary;
	}

	box-shadow: none;
}

input,
select,
textarea {
	@extend .body-text;
	@extend %bordered;
	width: 100%;
	resize: none !important;
	outline: none;
	background: #fff;
	color: $black-100;

	&::placeholder {
		color: $border-color;

		&:focus {
			color: $black-50;
		}
	}

	&.invalid {
		background: rgba(233, 0, 36, 0.1);
		border-color: #e90024;
	}

	&.large {
		padding: 14px;
	}

	&.small {
		padding: 9px 12px;
	}

	&.tiny {
		padding: 9px 8px;
	}
}

input[type="checkbox"] {
	-webkit-appearance: none;
	-moz-appearance: none;
	-ms-appearance: none;
	-o-appearance: none;
	appearance: none;
	width: 14px;
	height: 14px;
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	border-radius: 2px;
	background-color: white;
	background-position: center center;
	background-repeat: no-repeat;
	line-height: normal;
	box-sizing: border-box;
	padding: 9px !important;
	border-radius: 3px;
	border: 1px solid #d8d8d8;
	position: relative;
	margin: 0;

	&:checked {
		border-color: $black-100;
		background-color: #ffffff;
		background-size: 60%;
		background-position: center;
		background-image: url(/assets/images/checkmark.gif);
	}
}

form {
	.form-group {
		label:not(.form-check-label) {
			justify-content: space-between;
			font-size: 10px;
			letter-spacing: 1.5px;
			color: $black-60;
			font-weight: bold;
			text-transform: uppercase;
			margin-bottom: 4px;
		}

		label.form-check-label {
			margin-bottom: 0;
		}

		input,
		textarea {
			padding: 14px;
			outline: none;
		}
	}

	.was-validated .form-control:invalid,
	.form-control.is-invalid {
		border-color: $danger-default;
	}
}

.dropdown-menu {
	width: 100%;

	button.dropdown-item {
		letter-spacing: 0.2px;
		text-transform: none;

		&.active {
			background-color: #e6f1f8;
			color: $black;
		}
	}
}