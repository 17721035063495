@mixin cellPadding ($cellPadding: 10px 20px 10px 0px) {
  padding: $cellPadding;
  &:first-of-type {
    padding-left: 20px;
  }
  &:last-of-type {
    padding-right: 0;
  }
}


table {
  &.full-width {
    width: 100%;
  }
  font-size: 1.1em;
  border-collapse: collapse;
  tr {
    border-bottom: 1px solid #dddddd;
    th {
      text-transform: uppercase;
      @include cellPadding();
    }
    td {
      @include cellPadding();
    }
  }

}